.info-tooltip__close-button {
    margin-left: auto;
    padding-bottom: 10px;
    width: 32px;
    height: 32px;
    margin-right: calc(50vw - 220px);
    margin-bottom: 16px;
    background-image: url(./../../../images/CloseIcon.svg);
    background-color: black;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    border: 0;
    z-index: 999;
    background-color: transparent;
}

.info-tooltip__close-button:hover {
    opacity: 0.6;
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .info-tooltip__close-button {
        width: 20px;
        height: 20px;
        margin-right: calc(50vw - 220px);
        margin-bottom: 16px;
        right: 0px;
    }
}

@media screen and (max-width: 500px) {
    .info-tooltip__close-button {
        margin-right: calc(50vw - 149px); 
    }
}