.button-navigation-back__img {
    padding: 15px;
    margin-left: calc(5% - 15px);
    border-radius: 50px;
    transition: background-color 0.7s;
    width: 30px;
    height: 30px;
}

.button-navigation-back__img:hover {
    cursor: pointer;
    background-color: rgb(180, 180, 180);
}

@media screen and (max-width: 790px) {
    .button-navigation-back__img {
        width: 19px;
        height: 19px;
    }
}