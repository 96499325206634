.item-list-work__remove {
    font-size: 15px;
    font-weight: 500;
    width: 30px;
    height: 100%;
    text-align: center;
    border-radius: 50px;
    padding: 5px 0 5px 0;
    transition: background-color 0.7s;
    margin: 0 0 0 5px;
}

.item-list-work__remove:hover {
    cursor: pointer;
    background-color: rgb(186, 186, 186);
}

@media screen and (max-width: 790px) {
    .item-list-work__remove {
        padding: 0 0 3px 0;
    }
}