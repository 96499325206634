body {
  margin: 0;
}

.app {
  display: flex;
  background-color: white;
  min-height: 100vh;
  /* width: 100vw; */
  margin: 0;
  padding: 0;
  justify-content: center;
}

.app-page {
  display: flex;
  flex-direction: column;
  /* max-width: 1300px; */
  width: 95%;
  align-items: center;
}

p {
  color: black;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 350px) {
  .app-page {
    padding: 0 10px 0;
  }
}