.input__text-error {
    font-size: 12px;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    padding: 10px 0 0;
    color: rgb(253, 108, 108);
}

@media screen and (max-width: 790px) {
    .input__text-error {
        font-size: 10px;
        padding: 3px 0 0;
    }
}