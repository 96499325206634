.header-button {
    background-color: transparent;
    border: none;
    opacity: 1;
    transition: background-color 1.7s;
    border-radius: 50px;
    padding: 15px;
    border: 5px solid transparent;
    border-radius: 50%;
    transition: border 1s;
}

.header-button:hover {
    cursor: pointer;
    border: 5px solid rgb(180, 180, 180);
} 