.service__container-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 420px;
    margin: 30px 0;
}

@media screen and (max-width: 450px) {
    .service__container-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100px;
    }
}