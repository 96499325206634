.form-add-record {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 70px;
    max-width: 900px;
}


@media screen and (max-width: 790px) {
    .form-add-record {
        padding-top: 20px;
    }
}