.item-list-work__text{
    font-size: 17px;
    padding: 3px 0;
    margin: 0;
    white-space: 'normal';
}

@media screen and (max-width: 790px) {
    .item-list-work__text{
        font-size: 14px;
    }
}