.item-list-clients {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    transition: background-color 0.7s;
}

.item-list-clients:hover {
    cursor: pointer;
    background-color: rgb(223, 223, 223);
}