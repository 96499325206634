.item-list-work__container{
    display: flex;
    flex-direction: row;
    align-items: end;
    min-width: 20%;
    justify-content: space-between;
}

@media screen and (min-width: 790px) {
    .button-with-border:hover {
        cursor: pointer;
        background-color: yellow;
        border-color: yellow;
    }
}