.form-select-customer-record__button-container {
    padding: 30px 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* max-width: 300px; */
    margin: 0 auto;
}

@media screen and (max-width: 470px) {
    .form-select-customer-record__button-container {
        flex-direction: column;
        height: 100px;
    }
}