.login__container-text-password-recover-link {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid blue;
    color: rgba(0, 0, 255, 0.689);
    font-size: 14px;
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, ;
    transition: font-size 0.7s;
}

.login__container-text-password-recover-link:hover {
    cursor: pointer;
    font-size: 17px;
}