.form-add-record__title {
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    margin: 0;
    padding: 30px 0 60px;
}

@media screen and (max-width: 790px) {
    .form-add-record__title {
        font-size: 20px;
        padding: 30px 0 40px;
    }
}