.form-add-work{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0px;
    width: 100%;
}


@media screen and (max-width: 790px) {
    .form-add-work{
        padding-top: 0px;
    }
  }