.button-with-border {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 3px solid #000000;
    padding: 10px 30px;
    min-width: 130px;
    transition: background-color 0.7s, border-color 0.7s, opacity 0.7s;
}

@media screen and (max-width: 790px) {
    .button-with-border {
        padding: 10px 20px;
        border: 2px solid #000000;
        min-width: 120px;
    }
}

