.form-update-service__buttons {
    margin: 30px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media screen and (max-width:450px) {
    .form-update-service__buttons {
        margin: 30px 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 90px;
    }
}