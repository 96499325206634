.search-form__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    min-height: 72px;
    width: 100%;
    margin: 0;
}

@media screen and (max-width: 580px) {
    .search-form__container {
        width: 100%;
        flex-direction: column;
        border-radius: 9px 0 0 9px;
    }
}
