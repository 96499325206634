.form-add-transport__title{
    text-align: center;
    font-size: 22px;
    font-weight: 500;
}

@media screen and (max-width: 768px) {
    .form-add-transport__title{
        font-size: 18px;
    }
}