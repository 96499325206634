.account__service-container {
    width: 90%;
    background-color: rgb(218, 218, 218);
    padding: 30px;
    border-radius: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    /* gap: 20px; */
    align-items: center;
    margin: 20px 0 0;
}