.button-with-border__text {
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    padding: 0 !important;
    margin: 0;
}

@media screen and (max-width: 790px) {
    .button-with-border__text {
        font-size: 15px;
    }
}