.form-add-record__loader{
    width: 25px;
    position: absolute;
    right: 0;
    bottom: 23px;
}

@media screen and (max-width: 790px) {
    .form-add-record__loader{
        bottom: 10px;
    }
  }