.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 900px;
    padding: 10vh;
}

@media screen and (max-width: 900px) {
    .login {
        width: 90vw;
    }
}