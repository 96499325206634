.form-choice-edit-remove__container-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 0 30px;
}

@media screen and (max-width: 500px) {
    .form-choice-edit-remove__container-button {
        max-width: 230px;
    }
}